import React, { FC } from "react";

import moment from "moment";

import FilterClosed from "./filter-closed";
import FilterDate from "./filter-date";
import RefreshButton from "./refresh-button";

export interface ControlsProps {
  selectedDate: moment.Moment;
  handleDateChange: React.Dispatch<React.SetStateAction<moment.Moment>>;
  showClosed: boolean;
  setShowClosed: React.Dispatch<React.SetStateAction<boolean>>;
  refreshData: () => void;
}

const Controls: FC<ControlsProps> = ({
  selectedDate,
  handleDateChange,
  showClosed,
  setShowClosed,
  refreshData,
}) => {
  return (
    <div className="controls">
      <FilterDate
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
      />
      <FilterClosed showClosed={showClosed} setShowClosed={setShowClosed} />
      <RefreshButton refreshData={refreshData} />
    </div>
  );
};

export default Controls;
