import React, { FC } from "react";

import { Table } from "@material-ui/core";

import { DeliveryStatus } from "../app/app";
import UpdateTableHeader from "./update-table-header";
import { Order, OrderBy } from "./types";
import UpdateTableBody from "./update-table-body";

interface UpdateTableProps {
  updates: DeliveryStatus[];
}

const UpdatesTable: FC<UpdateTableProps> = ({ updates }) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<OrderBy>("surname");

  const handleRequestSort = (_: React.MouseEvent, property: OrderBy) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  return (
    <div className="delivery-data">
      <Table>
        <UpdateTableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <UpdateTableBody order={order} orderBy={orderBy} updates={updates} />
      </Table>
    </div>
  );
};

export default UpdatesTable;
