import React, { FC } from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { headCells, Order, OrderBy } from "./types";

export interface UpdateTableHeaderProps {
  order: Order;
  orderBy: OrderBy;
  onRequestSort: (event: React.MouseEvent, property: OrderBy) => void;
}

const UpdateTableHeader: FC<UpdateTableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((cell, i) =>
          cell.sort ? (
            <TableCell
              key={i}
              align={cell.align}
              sortDirection={orderBy === cell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === cell.id}
                direction={order}
                onClick={(e) => onRequestSort(e, orderBy)}
              >
                {cell.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={i}>{cell.label}</TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

export default UpdateTableHeader;
