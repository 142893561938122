import React, { FC } from "react";

import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import { DeliveryStatus } from "components/app/app";
import { Order, OrderBy } from "./types";

export interface UpdateTableBodyProps {
  updates: DeliveryStatus[];
  order: Order;
  orderBy: OrderBy;
}

const useStyles = makeStyles({
  closed: {
    backgroundColor: "lightgray",
  },
});

const UpdateTableBody: FC<UpdateTableBodyProps> = ({
  updates,
  order,
  orderBy,
}) => {
  const classes = useStyles();

  return (
    <TableBody>
      {stableSort(updates, getSorting(order, orderBy)).map(
        (row: DeliveryStatus, i: number) => {
          let { time, date } = getFormatedTimeDate(row.timestamp);

          return (
            <TableRow
              classes={row.closed ? { root: classes.closed } : {}}
              key={i}
            >
              <TableCell>{row.surname}</TableCell>
              <TableCell>{row.cardId}</TableCell>
              <TableCell>{row.careHome}</TableCell>
              <TableCell>{row.pharmacyForward}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.additionalInfo}</TableCell>
              <TableCell align="center">
                <span>{time}</span>
                <span>{date}</span>
              </TableCell>
              <TableCell>
                <img
                  src={"data:image/png;base64," + row.barcodeEncoding}
                  alt="barcode"
                />
                <span>
                  {row.barcodeLastFourChars &&
                  row.barcodeLastFourChars.length > 0
                    ? `Ending **${row.barcodeLastFourChars}`
                    : ""}
                </span>
              </TableCell>
            </TableRow>
          );
        }
      )}
    </TableBody>
  );
};

function getFormatedTimeDate(timestamp: string) {
  const time = moment(timestamp).format("HH:mm");
  const date = moment(timestamp).format("D MMM YYYY");

  return { time, date };
}

interface StatusSort {
  status: DeliveryStatus;
  index: number;
}

function stableSort(array: DeliveryStatus[], cmp: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el: DeliveryStatus, index: number) => {
    return { status: el, index } as StatusSort;
  });
  stabilizedThis.sort((a, b) => {
    const order = cmp(a.status, b.status);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((el) => el.status);
}

function getSorting(order: Order, orderBy: OrderBy) {
  return order === "desc"
    ? (a: DeliveryStatus, b: DeliveryStatus) => desc(a, b, orderBy)
    : (a: DeliveryStatus, b: DeliveryStatus) => -desc(a, b, orderBy);
}

function desc(a: DeliveryStatus, b: DeliveryStatus, orderBy: OrderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default UpdateTableBody;
