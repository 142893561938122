import React, { FC } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

interface FilterDateProps {
  selectedDate: moment.Moment;
  handleDateChange: React.Dispatch<React.SetStateAction<moment.Moment>>;
}

const FilterDate: FC<FilterDateProps> = ({
  selectedDate,
  handleDateChange,
}) => {
  return (
    <div className="filter__date">
      <div className="filter__date--picker">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            minDate={new Date(
              moment()
                .subtract(
                  parseInt(process.env.REACT_APP_MAX_CALENDAR_DAYS || "4"),
                  "days"
                )
                .format()
            ).toISOString()}
            disableFuture
            value={selectedDate}
            label="Date From:"
            onChange={() => handleDateChange(selectedDate)}
            autoOk
            format="DD/MM/YYYY"
            InputAdornmentProps={{
              position: "end",
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

export default FilterDate;
