import React, { useState, useEffect } from "react";
import "./app.scss";

import moment from "moment";

import Controls from "./../controls";
import Header from "./../header";
import UpdatesTable from "../table/";
import branchData from "../../branches.json";
import { filterClosed } from "../../selectors/records";
import { fetchData } from "../../api";
import { getBranchHeader } from "../../utils";

const BRANCH_ID = new URLSearchParams(window.location.search)
  .get("branchId")!
  .substr(2); //remove HC prefix

export interface Branch {
  branchId: number;
  branchName: string;
}

export interface DeliveryStatus {
  surname: string;
  cardId: string;
  careHome: string;
  pharmacyForward: string;
  status: string;
  additionalInfo: string;
  barcodeLastFourChars: string;
  closed: boolean;
  timestamp: string;
  barcodeEncoding: string;
}

export interface UpdateInformation {
  deliveryStatus: DeliveryStatus[];
  latestPslEvent: string;
  latestParagonEvent: string;
}

const App = () => {
  const [deliveryStatusRecords, setDeliveryStatusRecords] = useState<
    DeliveryStatus[]
  >([]);
  const [selectedDate, handleDateChange] = useState(
    moment().subtract(process.env.REACT_APP_DEFAULT_DAYS_AGO, "days")
  );
  const [showClosed, setShowClosed] = useState(false);
  const [latestPslEvent, setLatestPslEvent] = useState("");
  const [latestParagonEvent, setLatestParagonEvent] = useState("");

  useEffect(() => {
    async function setInitialState(dateFilter: moment.Moment) {
      const data: UpdateInformation = await fetchData(dateFilter, BRANCH_ID);
      setDeliveryStatusRecords(data.deliveryStatus);
      setLatestPslEvent(data.latestPslEvent);
      setLatestParagonEvent(data.latestParagonEvent);
    }
    setInitialState(selectedDate);
  }, [selectedDate]);

  async function refreshData() {
    const data = await fetchData(selectedDate, BRANCH_ID);
    if (data.deliveryStatus === null) return;
    setDeliveryStatusRecords(data.deliveryStatus);
    setLatestPslEvent(data.latestPslEvent);
    setLatestParagonEvent(data.latestParagonEvent);
  }

  return (
    <main>
      <div className="container">
        <Header
          branchName={getBranchHeader(branchData, BRANCH_ID)}
          latestPslEvent={latestPslEvent}
          latestParagonEvent={latestParagonEvent}
        />
        <Controls
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          showClosed={showClosed}
          setShowClosed={setShowClosed}
          refreshData={refreshData}
        />
        <UpdatesTable
          updates={filterClosed(deliveryStatusRecords, showClosed)}
        />
      </div>
    </main>
  );
};

export default App;
