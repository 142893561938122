export type Align = "left" | "center" | "right" | "inherit" | "justify";
export type Order = "asc" | "desc";
export type OrderBy =
  | "surname"
  | "cardId"
  | "careHome"
  | "pharmacyForward"
  | "status"
  | "additionalInfo"
  | "barcodeLastFourChars"
  | "closed"
  | "timestamp"
  | "barcodeEncoding";

export interface HeadCells {
  id: string;
  label: string;
  align: Align;
  sort: boolean;
}

export const headCells: HeadCells[] = [
  { id: "surname", label: "\u2195 Last Name", align: "left", sort: true },
  { id: "cardId", label: "Card ID", align: "left", sort: false },
  { id: "careHome", label: "Care Home", align: "left", sort: false },
  {
    id: "pharmacyForward",
    label: "C&C Target Pharmacy",
    align: "left",
    sort: true,
  },
  { id: "status", label: "\u2195 Status", align: "left", sort: true },
  {
    id: "additionalInfo",
    label: "Additional Info",
    align: "left",
    sort: false,
  },
  { id: "timestamp", label: "\u2195 Timestamp", align: "center", sort: true },
  { id: "bagId", label: "Bag ID", align: "center", sort: false },
];
