import moment from "moment";

export async function fetchData(dateFilter: moment.Moment, branchId: string) {
  const timeFormat = "YYYY-MM-DDTHH:mm:ss[Z]";
  const timeZero = { hours: 0, minutes: 0, seconds: 0 };
  const df = dateFilter.set(timeZero).format(timeFormat);

  const res = await fetch(
    `${process.env.REACT_APP_DELIVERY_STATUS_API}/updates?branchId=${branchId}&dateFilter=${df}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return await res.json();
}
