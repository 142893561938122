import React, { FC } from "react";
import { Button } from "@material-ui/core";
import { formatLatestDate } from "../../utils";

export interface HeaderProps {
  branchName: string;
  latestPslEvent: string;
  latestParagonEvent: string;
}

const Header: FC<HeaderProps> = ({
  branchName,
  latestPslEvent,
  latestParagonEvent,
}) => {
  return (
    <div className="header">
      <div className="headers">
        <h1>Home Delivery Bag Status Report</h1>
        <h3>{branchName}</h3>
        <h3>
          <span className="summaryLabel">Last PSL Event: </span>
          {formatLatestDate(latestPslEvent)}
        </h3>
        <h3>
          <span className="summaryLabel">Last Paragon Event: </span>
          {formatLatestDate(latestParagonEvent)}
        </h3>
      </div>
      <div className="print">
        <Button
          className="print__buton"
          variant="contained"
          onClick={() => window.print()}
        >
          Print Status Report
        </Button>
      </div>
    </div>
  );
};

export default Header;
