import React, { FC } from "react";
import { Checkbox } from "@material-ui/core";

interface FilterClosedProps {
  showClosed: boolean;
  setShowClosed: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterClosed: FC<FilterClosedProps> = ({ showClosed, setShowClosed }) => {
  return (
    <div className="filter__closed">
      <div className="filter__closed--text">Show Closed:</div>
      <div>
        <Checkbox
          data-testid={"chk/show-closed"}
          disableRipple
          value={showClosed}
          onChange={(event) => {
            setShowClosed(event.target.checked);
          }}
        />
      </div>
    </div>
  );
};

export default FilterClosed;
