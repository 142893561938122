import React, { FC } from "react";
import { Button } from "@material-ui/core";
import { getLastUpdated } from "../../utils";

interface RefreshButtonProps {
  refreshData: () => void;
}

const RefreshButton: FC<RefreshButtonProps> = ({ refreshData }) => {
  return (
    <div className="refresh">
      <span className="refresh__timestamp">
        Last updated: {getLastUpdated()}
      </span>
      <Button
        className="refresh__button"
        variant="contained"
        onClick={refreshData}
      >
        Refresh
      </Button>
    </div>
  );
};

export default RefreshButton;
